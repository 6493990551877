import { RouteName } from 'routes';
import { IconChevronLeftLine } from '@daangn/react-monochrome-icon';
import { AppBarProps, AppScreenOptions } from './ScreenLayout';

type DefaultAppScreenProps = {
  /** 앱바를 사용하지 않을 경우, true로 설정해요. (appBar과 함께 사용 불가) */
  withoutAppBar?: boolean;
  /** AppScreenOptions의 appBar 속성이에요.  */
  appBar?: AppBarProps;
} & Omit<AppScreenOptions, 'appBar'>;

export const defaultScreenProps: Partial<Record<RouteName, DefaultAppScreenProps>> = {
  article_detail: {
    appBar: {
      closeButton: <IconChevronLeftLine size={24} />,
      border: false,
      tone: 'transparent',
    },
  },
};
