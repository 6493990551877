import { ErrorBoundary, FallbackRender } from '@sentry/react';
import { StackflowReactPlugin } from '@stackflow/react';
import PageLayout from 'components/Base/PageLayout';
import PageLoading from 'components/Base/PageLoading';
import MagicButton from 'components/Dev/MagicButton';
import ErrorPage from 'components/Error/ErrorPage';
import { CommonError } from 'constants/error';
import Path from 'constants/path';
import { Suspense } from 'react';
import { RouteName } from 'routes';
import ScreenLayout from 'stackflow/components/ScreenLayout';

const ErrorFallback: FallbackRender = ({ error }) => {
  if (error.name === 'ChunkLoadError') {
    return (
      <ErrorPage
        buttonOption={{
          label: '새로고침',
          onClick: () => (window.location.href = Path.Main),
        }}
      />
    );
  }

  if (error instanceof CommonError) {
    return <ErrorPage>{error.message}</ErrorPage>;
  }

  return <ErrorPage />;
};

const V3_ACTIVITIES: RouteName[] = ['article_detail'];

const routeWrapperPlugin = (): StackflowReactPlugin => () => {
  return {
    key: 'hoc',
    wrapActivity({ activity }) {
      const isV3Activity = V3_ACTIVITIES.includes(activity.name as RouteName);

      if (isV3Activity) {
        return (
          <ScreenLayout>
            <ErrorBoundary fallback={ErrorFallback}>
              <Suspense fallback={<PageLoading />}>{activity.render()}</Suspense>
            </ErrorBoundary>
          </ScreenLayout>
        );
      }

      return (
        <PageLayout>
          <ErrorBoundary fallback={ErrorFallback}>
            <Suspense fallback={<PageLoading />}>{activity.render()}</Suspense>
          </ErrorBoundary>
        </PageLayout>
      );
    },
    wrapStack({ stack }) {
      return (
        <>
          {stack.render()}
          <MagicButton />
        </>
      );
    },
  };
};

export default routeWrapperPlugin;
