import 'src/basicUIPlugin.css.ts.vanilla.css?source=#H4sIAAAAAAAAA+3Y0UrDMBQG4Ps9RUAEBVPa6hxk+CQictqcrsetOTU5nUPx3UVxeqPWdqIOAr3oRf/0h5x8F0mus9tze5PfpOphopTWQaBcViu+0+2qW5DTBQQqdUe6gHK58Nw5q0tesTfqoKqqeU/KUvNB0i8KOEpP1OuTZNPjvoXEgwskxE7bzsPzi1Fp6IuV3LSdoB2bh7bVBXhdsLfoP+ufznr7f7TQD5UKdI9GZe3mu7kaaVGLUafJ1GMzLLVr54bcyP9Tye5t9PIsz/Kz4dFd6wtuZGSJ9+jO+z7qKH6W3rUNr9E/f2BUTdaiGzIJASrU4BE0uYCihVuj0v5JLliEGx1qRNkeAw+WumBU9o2ZatjCanywgY2+Iyu1UVmaHs4nj5NkK2kWJd1zSdNkOtzSPJn9LaYDC0RNo6b/V1PjmeXSgsBXkNXY4EXZteiFHF9FeCO8Ed4Ib4T3V+AFZz2TjezuO7vx5iCSG8n9+5uD6YukLZMT9BrX6CQY5djhfPL4BDdqRpiqFQAA';
import 'src/styles/f.css.ts.vanilla.css?source=LmRodGh4cTAgewogIHdpZHRoOiAxMDAlOwp9Ci5kaHRoeHExIHsKICBoZWlnaHQ6IDEwMCU7Cn0KLmRodGh4cTIgewogIHRvcDogMDsKfQouZGh0aHhxMyB7CiAgbGVmdDogMDsKfQouZGh0aHhxNCB7CiAgcG9zaXRpb246IGFic29sdXRlOwp9Ci5kaHRoeHE1IHsKICBwb3NpdGlvbjogcmVsYXRpdmU7Cn0KLmRodGh4cTcgewogIGRpc3BsYXk6IGZsZXg7Cn0KLmRodGh4cTggewogIGZsZXg6IDE7Cn0KLmRodGh4cTkgewogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLmRodGh4cWEgewogIGFsaWduLWl0ZW1zOiBmbGV4LWVuZDsKfQouZGh0aHhxYiB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLmRodGh4cWMgewogIG92ZXJmbG93OiBoaWRkZW47Cn0KLmRodGh4cWQgewogIHdoaXRlLXNwYWNlOiBub3dyYXA7Cn0KLmRodGh4cWUgewogIHRleHQtYWxpZ246IGNlbnRlcjsKfQouZGh0aHhxZiB7CiAgY3Vyc29yOiBwb2ludGVyOwogIC13ZWJraXQtdGFwLWhpZ2hsaWdodC1jb2xvcjogdHJhbnNwYXJlbnQ7Cn0KLmRodGh4cWcgewogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7Cn0KLmRodGh4cWggewogIGJveC1zaXppbmc6IGNvbnRlbnQtYm94Owp9Ci5kaHRoeHFpIHsKICBhcHBlYXJhbmNlOiBub25lOwogIGJvcmRlcjogMDsKICBwYWRkaW5nOiAwOwogIGJhY2tncm91bmQ6IG5vbmU7Cn0=';
import 'src/components/AppScreen.css.ts.vanilla.css?source=#H4sIAAAAAAAAA9VUy5KbMBC8+yvmkiqoIJfwrvOQL/mT1IBkLAMSEcKPpPbfUwhYAzbY3solnFSMpqenpzXLnyHPtq8vr1/hzwIgwjhNjK4UJ7HOtGFwQOMRUlqM022mj6TIqkQqEmEpY1JJwmVOxln+ZvG2WHbI3z6IPI/63aFag6qUVmrFmvNWm7xF7m5SPwBdYCzt+SoygOQXyBqmRczQihfuhZR+CoAGMErau6QWnwHdLAB+E6m4OLFRubDL/PWF71d7CiOQnZDJzjIIqRH5ZqAZg0wqgYYkBrkUynofmksAJonQo00jdS+O0gLc904He8yCcTDqBZvYQIDwFmA8B8jnAelA7rQJHoRxXZ8ZlLHRWVbLRY4iSqUl79EmJFXCwOoq3s3MZjWcaspYh9aARGhcZS7LIsMzA6WVGIwzhBHJx4zUquH0uALqDSINZm/2ppJ2F2dI0KGVrwyZ3nD1NJQz7HRD9OGG6P2G+kZ7tL0GJnP5l5Gv768gLAoSoSHNy/Q3/2DjBJCjqYtYXTxZn1xqE14ZrA+O0wVxbOu1i3eLJcYsdv4DcnXPnzJzfsdHrZ8nraTm8te3l6p2OUfJ7Y5BuFyt24U48XhfhtlFU/GixlMDnhbrYRhH50dZFYU2tgSvQM7dFsrx5NHi5PuAivf+x1qVFpX1StwKgkYgkaoUth6p77t2RjYeGdmRfZIlfHZ8Hs3KpSK36AV32DtR354URKjD/6nFJPFOhr+BifjQcQkAAA==';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var allTransitions = '_1dlf4349';
export var appScreen = _7a468({defaultClassName:'dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 dhthxqc',variantClassNames:{transitionState:{'enter-active':'_1dlf434a','enter-done':'_1dlf434b','exit-active':'_1dlf434c','exit-done':'_1dlf434d'}},defaultVariants:{},compoundVariants:[]});
export var background = '_1dlf4348';
export var dim = '_1dlf434j dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 _1dlf4347 _1dlf4349';
export var edge = _7a468({defaultClassName:'_1dlf434o dhthxq4 dhthxq2 dhthxq3 dhthxq1',variantClassNames:{hasAppBar:{true:'_1dlf434p'}},defaultVariants:{},compoundVariants:[]});
export var enterActive = '_1dlf434a';
export var enterDone = '_1dlf434b';
export var exitActive = '_1dlf434c';
export var exitDone = '_1dlf434d';
export var paper = _7a468({defaultClassName:'_1dlf434k dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 _1dlf4348 _1dlf4349',variantClassNames:{hasAppBar:{true:'_1dlf434l dhthxqg'},modalPresentationStyle:{fullScreen:'_1dlf434m'},activityEnterStyle:{slideInLeft:'_1dlf434n'}},defaultVariants:{},compoundVariants:[]});
export var vars = {transitionDuration:'var(--_1dlf4340)',zIndexes:{dim:'var(--_1dlf4341)',paper:'var(--_1dlf4342)',edge:'var(--_1dlf4343)',appBar:'var(--_1dlf4344)'},appBar:{topMargin:'var(--_1dlf4345)',center:{mainWidth:'var(--_1dlf4346)'}}};