import { Portal } from '@daangn/karrot-clothes';
import { useActivity } from '@stackflow/react';
import { useMounted } from '@stackflow/react-ui-core';
import { PropsWithChildren } from 'react';

const PageContentPortal = ({ children }: PropsWithChildren<unknown>) => {
  const activity = useActivity();
  const activityId = activity?.id;
  const mounted = useMounted();

  return (
    <Portal
      selector={
        mounted && activityId
          ? `[data-stackflow-activity-id="${activityId}"][data-part="paper"]`
          : undefined
      }
    >
      {children}
    </Portal>
  );
};

export default PageContentPortal;
