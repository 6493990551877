import { mapInitialActivityPlugin } from '@stackflow/plugin-map-initial-activity';
import Path from 'constants/path';

const redirectPlugin = () =>
  mapInitialActivityPlugin({
    mapper(url) {
      if (url.pathname.includes(Path.SimpleNewArticle)) {
        return {
          activityName: 'article_new',
          activityParams: {},
        };
      }
      return null;
    },
  });

export default redirectPlugin;
