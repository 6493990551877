export default class EventTarget {

  listeners: Map<string, Set<EventListener>>;

  constructor() {
    this.listeners = new Map();
  }

  addEventListener(type: string, callback: EventListener) {
    if (!this.listeners.has(type)) {
      this.listeners.set(type, new Set());
    }
    this.listeners.get(type)!.add(callback);
  }

  removeEventListener(type: string, callback: EventListener) {
    if (this.listeners.has(type)) {
      this.listeners.get(type)!.delete(callback);
    }
  }

  dispatchEvent(event: Event) {
    if (this.listeners.has(event.type)) {
      for (const callback of this.listeners.get(event.type)!) {
        callback.call(this, event);
      }
    }
    return !event.defaultPrevented;
  }
}