import { HStack, Stack } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  title: string;
  description?: string;
  spacing?: number;
}>;

const FilterSection = ({ title, description, spacing = 12, children }: Props) => {
  return (
    <Stack spacing={spacing}>
      {title && (
        <HStack spacing={6}>
          <Text variant="subtitle1Bold">{title}</Text>
          {description && (
            <Text variant="subtitle2Regular" color="gray700">
              {description}
            </Text>
          )}
        </HStack>
      )}
      <div>{children}</div>
    </Stack>
  );
};

export default FilterSection;
