import camelcaseKeys from "camelcase-keys";
import { makeId } from "./core/makeId";
import { getCurrentEnvironment } from "./core/getCurrentEnvironment";
import { Message } from "./core/Message";
import {
  KarrotBridgeSchema,
  MetaBridgeDriver,
} from "./__generated__/makeKarrotBridge";

import EventTarget from "./core/EventTarget"

declare global {
  interface Window {
    native?: KarrotBridgeDriver;
  }
}

export type Listener = (message: Message) => void;

export interface KarrotBridgeDriver extends MetaBridgeDriver {
  getCurrentEnvironment: typeof getCurrentEnvironment;
  postMessage(message: Message): void;
  addMessageListener(listener: Listener): () => void;
  onMessage(message?: Message): void;
  compat: {
    /**
     * (Compat) 이미지 피커를 열어요.
     */
    pickImages: () => Promise<Blob[]>;

    /**
     * (Compat) 이벤트 이름을 기반으로 Stream을 구독해요.
     */
    subscribeStream: (
      eventName: string,
      listener: (data?: any | null) => void
    ) => () => void;
  };
}

export function installKarrotBridgeDriver() {
  const listeners: Listener[] = [];
  const globalEventEmitter = new EventTarget();

  let globalSubscriptionDisposer: null | (() => void) = null;

  const postMessage = (message: Message) => {
    switch (getCurrentEnvironment()) {
      case "Android":
        window.AndroidFunction?.messageHandler(JSON.stringify(message));
        break;
      case "Cupertino":
        window.webkit?.messageHandlers.messageHandler.postMessage(
          JSON.stringify(message)
        );
        break;
      case "Web":
        console.warn(
          "KarrotBridge의 통신 드라이버는 일반 데스크탑, 모바일 브라우저 환경을 지원하지 않아요." +
            " 현재 환경이 당근마켓 WebView 환경인지 확인해주세요."
        );
        break;
    }
  };

  const addMessageListener = (listener: Listener) => {
    listeners.push(listener);

    return function dispose() {
      const i = listeners.findIndex((l) => l === listener);
      listeners.splice(i, 1);
    };
  };

  const onMessage = (message?: Message) => {
    if (!message) {
      return console.error("메시지가 빈 채로 도착했어요");
    }
    listeners.map((l) => l(message));
  };

  const onQueried = (queryName: string, requestBody: any) => {
    const id = makeId();

    const message = {
      id,
      type: queryName,
      payload: requestBody,
    };

    postMessage(message);

    return new Promise((resolve, reject) => {
      const dispose = addMessageListener((arrivedMessage) => {
        if (arrivedMessage.id === message.id) {
          dispose();

          if ("error" in arrivedMessage) {
            reject(new Error(arrivedMessage.error));
          } else {
            resolve(camelcaseKeys(arrivedMessage.payload, { deep: true }));
          }
        }
      });
    });
  };

  const onSubscribed = (
    subscriptionName: string,
    requestBody: any,
    listener: (error: Error | null, response: any) => void
  ) => {
    const id = makeId();

    const message = {
      id,
      type: subscriptionName,
      payload: requestBody,
    };

    postMessage(message);

    return addMessageListener((arrivedMessage) => {
      if (arrivedMessage.id === message.id) {
        if ("error" in arrivedMessage) {
          listener(new Error(arrivedMessage.error), null);
        } else {
          listener(null, camelcaseKeys(arrivedMessage.payload, { deep: true }));
        }
      }
    });
  };

  const subscriptionResponseListener = (
    error: Error | null,
    response: {
      stream: {
        eventName: string;
        data?: string;
      };
    } | null
  ) => {
    if (error) {
      throw error;
    }
    if (!response?.stream?.eventName) {
      throw new Error("이벤트 이름을 찾을 수 없습니다");
    }

    const { eventName } = response.stream;

    globalEventEmitter.dispatchEvent(
      new CustomEvent(eventName, {
        detail: response.stream.data
          ? JSON.parse(response.stream.data)
          : undefined,
      })
    );
  };

  const pickImages: KarrotBridgeDriver["compat"]["pickImages"] = async () => {
    switch (getCurrentEnvironment()) {
      case "Cupertino":
        return pickImagesInCupertino({
          onQueried,
        });
      default:
        return pickImagesInAndroid();
    }
  };

  const subscribeStream: KarrotBridgeDriver["compat"]["subscribeStream"] = (
    eventName,
    listener
  ) => {
    if (!globalSubscriptionDisposer) {
      globalSubscriptionDisposer = driver.onSubscribed(
        "REQ.STREAM.SUBSCRIBE",
        {},
        subscriptionResponseListener
      );
    }

    const cb = (e: Event) => {
      listener((e as CustomEvent).detail);
    };

    globalEventEmitter.addEventListener(eventName, cb);

    return function dispose() {
      globalEventEmitter.removeEventListener(eventName, cb);
    };
  };

  const driver: KarrotBridgeDriver = {
    getCurrentEnvironment,
    postMessage,
    addMessageListener,
    onMessage,
    onQueried,
    onSubscribed,
    compat: {
      pickImages,
      subscribeStream,
    },
  };

  if (typeof window !== "undefined") {
    if (window.native) {
      throw new Error(
        "KarrotBridge의 통신 드라이버가 이미 설치되어 있어요." +
          " 기존에 설치된 드라이버를 덮어쓰므로 비정상적인 작동이 있을 수 있어요." +
          " installKarrotBridgeDriver가 여러번 호출되고 있는지 확인해주세요." +
          " (<React.StrictMode />의 영향일 수 있어요.)"
      );
    }

    window.native = driver;
  }

  function uninstall() {
    if (typeof window !== "undefined") {
      delete window.native;
    }
  }

  return {
    driver,
    uninstall,
  };
}

const pickImagesInAndroid = async () => {
  const fileList = await new Promise<FileList | null>((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.style.display = "none";
    input.accept = "image/*";
    input.addEventListener("change", function () {
      resolve(this.files);
    });
    input.addEventListener("cancel", function () {
      resolve(null);
    });

    document.body.appendChild(input);

    input.click();
  });

  if (!fileList) {
    return [];
  }

  const blobs: Blob[] = [];

  for (let i = 0; i < fileList.length; i++) {
    blobs.push(fileList[i]);
  }

  return blobs;
};

const pickImagesInCupertino = async ({
  onQueried,
}: {
  onQueried: MetaBridgeDriver["onQueried"];
}) => {
  const response: KarrotBridgeSchema["ReqImagePickResponse"] = (await onQueried(
    "REQ.IMAGE.PICK",
    {}
  )) as any;

  if (!response?.image?.images) {
    return [];
  }
  const imgs = await Promise.all(
    response.image.images
      .map((image) => image.uri)
      .map((imageUrl) => {
        const img = document.createElement("img");
        img.src = imageUrl;
        img.crossOrigin = "anonymous";
        return img;
      })
      .map((img) => {
        return new Promise<HTMLImageElement>((resolve) => {
          img.onload = () => {
            resolve(img);
          };
        });
      })
  );

  const canvases = imgs.map((img) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const imgWidth = img.naturalWidth;
    const imgHeight = img.naturalHeight;

    canvas.width = imgWidth;
    canvas.height = imgHeight;
    ctx?.drawImage(img, 0, 0, imgWidth, imgHeight);

    return canvas;
  });

  const blobs = await Promise.all<Blob | null>(
    canvases.map((canvas) => {
      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        });
      });
    })
  );

  imgs.forEach((img) => {
    img.remove();
  });

  return blobs.filter((blob): blob is NonNullable<typeof blob> => !!blob);
};
