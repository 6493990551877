/**
 * @generated SignedSource<<bc98cdbfdae5c35161afeceaf45a9ad9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Gender = "FEMALE" | "MALE" | "UNSPECIFIED";
import { FragmentRefs } from "relay-runtime";
export type userIdentification_user$data = {
  readonly id: string;
  readonly karrotUserIdentification: {
    readonly birthDate: {
      readonly day: number;
      readonly month: number;
      readonly year: number;
    } | null;
    readonly di: string;
    readonly gender: Gender;
    readonly karrotUserId: string;
    readonly phoneNumber: string;
    readonly realName: string;
    readonly verifyTime: string | null;
  } | null;
  readonly " $fragmentType": "userIdentification_user";
};
export type userIdentification_user$key = {
  readonly " $data"?: userIdentification_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"userIdentification_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "userIdentification_user"
};

(node as any).hash = "46549e867e694ba9faa9005f83b7b7f0";

export default node;
