import { isNumber } from 'lodash-es';
import React, {
  Children,
  cloneElement,
  CSSProperties,
  ElementType,
  forwardRef,
  Fragment,
  HTMLAttributes,
  isValidElement,
  useMemo,
} from 'react';
import { cn } from 'styles/utils';
import { tv } from 'tailwind-variants';

type StackDirection = 'column' | 'column-reverse' | 'row' | 'row-reverse';

interface StackOptions {
  /**
   * The direction to stack the items.
   * @default "column"
   */
  direction?: StackDirection;
  /**
   * The gap between each stack item (If divider exist, spacing will applied to margin)
   * @type CSSProperties["margin"]
   * @example "1rem" | "1em" | 1
   * @default "0.5rem"
   */
  spacing?: CSSProperties['margin'];
  /**
   * If `true`, each stack item will show a divider
   * @type React.ReactElement
   */
  divider?: React.ReactElement;
  /**
   * The HTML tag or React component to render the stack as.
   * @default "div"
   */
  as?: ElementType;
}

export type StackProps = StackOptions & HTMLAttributes<HTMLDivElement>;

// [참고] https://github.com/chakra-ui/chakra-ui/blob/v2/packages/components/src/stack/stack.tsx
export const Stack = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  const {
    direction = 'column',
    spacing = '0.5rem',
    divider,
    children,
    className,
    style,
    as: Component = 'div',
    ...rest
  } = props;

  const styles = stackStyles({ direction });

  const clones = useMemo(() => {
    if (!divider) return children;

    return getValidChildren(children).map((child, index, arr) => {
      const key = typeof child.key !== 'undefined' ? child.key : index;
      const sep = cloneElement(divider, {
        className: cn(styles.divider(), divider.props.className),
        css: divider.props.style,
      });

      return (
        <Fragment key={key}>
          {child}
          {index === arr.length - 1 ? null : sep}
        </Fragment>
      );
    });
  }, [children, divider]);

  return (
    <Component
      ref={ref}
      style={
        {
          ...style,
          '--stack-spacing': isNumber(spacing) ? `${spacing}px` : spacing,
        } as React.CSSProperties
      }
      className={cn(styles.root(), !divider && 'gap-[--stack-spacing]', className)}
      {...rest}
    >
      {clones}
    </Component>
  );
});

const getValidChildren = (children: React.ReactNode) => {
  return Children.toArray(children).filter(isValidElement);
};

const stackStyles = tv({
  slots: {
    root: 'flex',
    divider: 'flex border-solid self-stretch',
  },
  variants: {
    direction: {
      column: {
        root: 'flex-col',
        divider: 'border-b border-l-0 my-[--stack-spacing] mx-0',
      },
      'column-reverse': {
        root: 'flex-col-reverse',
        divider: 'border-b border-l-0 my-[--stack-spacing] mx-0',
      },
      row: {
        root: 'flex-row',
        divider: 'border-l border-b-0 mx-[--stack-spacing] my-0',
      },
      'row-reverse': {
        root: 'flex-row-reverse',
        divider: 'border-l border-b-0 mx-[--stack-spacing] my-0',
      },
    },
  },
  defaultVariants: {
    direction: 'column',
  },
});
