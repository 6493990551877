import { Activity } from '@stackflow/core';
import { matchPath } from 'react-router-dom';
import routes, { RouteName } from 'routes';
import { actions } from 'stackflow';

export const getLastActivity = () => {
  return actions
    .getStack()
    .activities?.filter((v) => v.transitionState !== 'exit-done')
    .slice(-2)?.[0] as (Activity & { name: RouteName }) | undefined;
};

export const getCurrentPageRouteName = () => {
  const routename = routes.find((v) =>
    matchPath(window.location.pathname || '', {
      path: v.path,
      exact: true,
    })
  )?.name;

  return routename;
};

// prev 는 replace 도 포함
class PrevActivity {
  private prevActivity: Activity | null = null;

  get() {
    return this.prevActivity;
  }

  set(activity: Activity) {
    this.prevActivity = activity;
  }
}

export const prevActivity = new PrevActivity();
