import { useActivity } from '@stackflow/react';
import { useEffect } from 'react';
import Logger from 'utils/Logger';
import {
  DefaultPvEventProvider,
  LoggerPageViewEventProperties,
  LoggerPageViewParams,
  LoggerPageViewType,
} from 'logger/schema/pvSchema';
import { without } from 'lodash-es';

// bridge - 액티비티 첫 진입시 로그 한번
// 그 외 - 액티비티 활성화시마다 로그
const usePageLog = <T extends LoggerPageViewType>(name: T, params: LoggerPageViewParams<T>) => {
  const { isActive } = useActivity();

  const eventProps = LoggerPageViewEventProperties[name];
  const paramPayload = {
    name: name,
    description: eventProps.description,
    ...params,
  };

  useEffect(() => {
    const providers = eventProps.provider || DefaultPvEventProvider;
    Logger.pageView(paramPayload, without(providers, 'ga4'));
  }, []);

  useEffect(() => {
    if (isActive) {
      Logger.pageView(paramPayload, ['ga4']);
    }
  }, [isActive]);
};

export default usePageLog;
