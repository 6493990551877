import React, { forwardRef } from 'react';
import { AppBar, BackButton, CloseButton, Left, Right } from 'seed-design/stackflow/AppBarV3';
import { PropsOf } from '@chakra-ui/system';
import { karrotBridge } from 'bridge';
import { useActivity } from '@stackflow/react';

const APPBAR_ID_PREFIX = 'realty-navbar-';

/**
 * @param activityId useActivity()를 통해 얻은 현재 액티비티의 id
 * @returns 현재 액티비티의 AppBar ID (ex. realty-navbar-629fb2a858338)
 */
export const getAppBarID = (activityId: string) => {
  return `${APPBAR_ID_PREFIX}${activityId}`;
};

/**
 * @param activityId useActivity()를 통해 얻은 현재 액티비티의 id
 * @returns 현재 액티비티의 AppBar 요소
 */
export const getAppBar = (activityId: string) => {
  const id = getAppBarID(activityId);
  const appBar = document.getElementById(id);
  return appBar;
};

type Props = {
  closeButton?: React.ReactNode;
  backButton?: React.ReactNode;
  renderRight?: React.ReactNode;
} & PropsOf<typeof AppBar>;
const RealtyAppBar = forwardRef<HTMLDivElement, Props>(
  ({ closeButton, backButton, renderRight, children, ...props }: Props, ref) => {
    const { id } = useActivity();

    return (
      <AppBar ref={ref} {...props} id={getAppBarID(id)}>
        <Left>
          <CloseButton onClick={() => karrotBridge.closeRouter({})}>
            {closeButton && closeButton}
          </CloseButton>
          <BackButton>{backButton && backButton}</BackButton>
        </Left>

        <Right>{renderRight && renderRight}</Right>

        {children}
      </AppBar>
    );
  }
);

export default RealtyAppBar;
