import { Stack } from '@chakra-ui/layout';
import withDev from 'hoc/withDev';
import { Radio, RadioGroup } from '@daangn/sprout-components-radio-group';
import groupState, { ClientGroupList } from 'store/group';
import { vars } from '@seed-design/design-token';
import { useAtom } from 'jotai';
import { uniq } from 'lodash-es';

const GroupMenuItem = () => {
  const [groups, setGroups] = useAtom(groupState);

  return (
    <Stack spacing={8}>
      <label>클라이언트 지정 그룹 변경</label>
      {Object.entries(ClientGroupList).map(([groupName, groupValues]) => (
        <div key={groupName}>
          <label css={vars.$semantic.typography.label4Bold}>{groupName}</label>
          <RadioGroup
            size="small"
            value={groups[groupName as keyof typeof groups]}
            onChange={(value) =>
              setGroups((prev) => ({
                ...prev,
                [groupName]: value,
              }))
            }
            orientation="horizontal"
          >
            {uniq(groupValues).map((groupValue) => (
              <Radio key={groupValue} value={groupValue}>
                {groupValue}
              </Radio>
            ))}
          </RadioGroup>
        </div>
      ))}
    </Stack>
  );
};

export default withDev(GroupMenuItem);
