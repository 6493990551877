import { atom } from 'jotai';
import { atomFamily, atomWithStorage } from 'jotai/utils';
import { LocalStorage, LocalStorageKey } from 'utils/Storage';

/** 본 매물(viewedArticle) */
// watchedArticle로 사용하다가 관심 서버 필드명(watch)와 혼동되어 SeenArticle으로 변경 (24.12.18)
// Storage, Store은 변경되었으나 변수명, 페이지 등 watchedArticles로 사용하고 있는 곳 존재
export const viewedArticlesState = atomWithStorage(
  LocalStorage.getKey(LocalStorageKey.ViewedArticles),
  {},
  {
    getItem: (_) => {
      return LocalStorage.getViewedArticles();
    },
    setItem: (_, newValue) => {
      LocalStorage.setViewedArticles(newValue);
    },
    removeItem: () => {
      LocalStorage.removeItem(LocalStorageKey.ViewedArticles);
    },
  },
  { getOnInit: true }
);

export const isViewedArticleState = atomFamily((articleId: string) =>
  atom((get) => get(viewedArticlesState)[articleId] || false)
);
