import { HStack } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import ArticleListSkeleton from 'components/Base/Skeleton/ArticleListSkeleton';
import Placeholder from 'components/Base/Skeleton/Placeholder';
import PlaceHolder from 'components/Base/Skeleton/Placeholder';

const MainSectionSkeleton = () => {
  return (
    <div className="pt-1">
      <div className="pb-1">
        <Placeholder.Text
          css={css`
            width: 100%;
            ${vars.$semantic.typography.bodyM2Regular}
            padding: 16px;
            border-radius: 6px;
          `}
        >
          1개 조건으로 알림 받는 중
        </Placeholder.Text>
      </div>
      <div className="h-1" />
      <HStack pt={4} pb={8} spacing={8}>
        <PlaceHolder.Chip size="small" width={96} />
        <PlaceHolder.Chip size="small" width={124} />
        <PlaceHolder.Chip size="small" width={68} />
        <PlaceHolder.Chip size="small" width={68} />
      </HStack>
      <div className="py-4">
        <ArticleListSkeleton />
      </div>
    </div>
  );
};

export default MainSectionSkeleton;
