import { getUrlWithQuery, overwriteQuery } from 'utils/Url';

type ThumbnailType = 'card' | 'cardLarge' | 'detail' | 'page';

type ImageOption = {
  width: number;
  height: number;
  quality?: number;
  // https://daangn.slack.com/archives/C02SLBZBYM8/p1662445451904049?thread_ts=1662444804.150399&cid=C02SLBZBYM8
  type?: 'crop' | 'cover' | 'inside';
  // https://github.com/daangn/hoian-lambda-thumbnail-v2/blob/a5e2a4eb71213a135d5a74959e324881810bcce5/lambda/origin-response-function/index.js#L25
  format?: 'jpg' | 'jpeg' | 'png' | 'gif' | 'webp' | 'svg' | 'tiff';
};

const DefaultImageOption = {
  type: 'crop',
  format: 'webp',
  quality: 95,
};

const ThumbnailOption: Record<ThumbnailType, ImageOption> = {
  card: {
    width: 300,
    height: 300,
  },
  cardLarge: {
    width: 600,
    height: 600,
  },
  detail: {
    width: 1000,
    height: 1000,
    type: 'inside',
  },
  page: {
    width: 1440,
    height: 1440,
    type: 'inside',
  },
};

export const getThumbnailUrl = (
  url: string,
  options?: {
    thumbnailType?: ThumbnailType;
    placeholder?: boolean;
  }
) => {
  if (!url.includes('cloudfront.net') && !url.includes('gcp-karroter.net')) {
    return url;
  }

  const { thumbnailType, placeholder } = options || {};
  const imageOptionsByType = ThumbnailOption[thumbnailType || 'card'];
  const placeholderOptions = placeholder ? { quality: 1 } : {};
  const { width, height, quality, type, format } = {
    ...DefaultImageOption,
    ...imageOptionsByType,
    ...placeholderOptions,
  };

  return overwriteQuery(url, { s: `${width}x${height}`, q: quality, t: type, f: format });
};

// https://developers.cloudflare.com/images/image-resizing/url-format/
type CFImageOptimizeOption = {
  width?: number;
  height?: number;
};

export const getCFOptimizeImage = (url: string, option?: CFImageOptimizeOption) => {
  return getUrlWithQuery(url, option);
};
