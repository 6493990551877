import GlobalLoading from 'components/Base/GlobalLoading';
import { useMemo } from 'react';
import type { RouteName } from 'routes';
import { defaultScreenProps } from 'stackflow/components/DefaultScreenProps';
import { getCurrentPageRouteName } from 'stackflow/utils';
import { cn } from 'styles/utils';
import { getCurrentPageSkeleton } from 'utils/skeleton';

const AppLoading = () => {
  const routeName = getCurrentPageRouteName();
  const MatchingSkeleton = useMemo(() => getCurrentPageSkeleton(), []);
  const defaultScreenOptions = defaultScreenProps[routeName as RouteName];
  // TODO: V3 AppBar 여부를 판단할 수 있는 flag 나오면 수정
  const showAppBar = defaultScreenOptions?.appBar?.tone !== 'transparent';

  if (MatchingSkeleton) {
    return (
      <div>
        <div
          className={cn(
            showAppBar &&
              'mt-[max(var(--stackflow-plugin-basic-ui-app-bar-min-safe-area-inset-top),env(safe-area-inset-top))] min-h-11'
          )}
        />
        <MatchingSkeleton />
      </div>
    );
  }

  return <GlobalLoading />;
};

export default AppLoading;
