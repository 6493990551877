import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { HStack } from 'components/Base/Stack/HStack';
import { Stack } from 'components/Base/Stack/Stack';
import { PropsWithChildren } from 'react';

const ArticleCardStyle = {
  Container: styled.div`
    background-color: ${vars.$semantic.color.paperDefault};
  `,
  Body: styled(HStack)`
    align-items: stretch;
  `,
  Content: styled(Stack)`
    width: 100%;
  `,
  Label: styled.h3`
    ${vars.$semantic.typography.label4Bold}
  `,
  Title: styled.h3`
    ${vars.$semantic.typography.subtitle1Bold}
  `,
  SubTitle: styled.h4`
    ${vars.$semantic.typography.subtitle2Regular}
  `,
  Information: ({ children }: PropsWithChildren) => (
    <div className="text-gray700 caption1Regular text-ellipsis-2">{children}</div>
  ),
  DateText: ({ children }: PropsWithChildren) => (
    <div className="text-gray600 caption2Regular text-ellipsis-1">{children}</div>
  ),
  AdvertisementHelper: styled.div`
    display: inline-flex;
    flex-shrink: 0;
    color: ${vars.$scale.color.gray600};
    ${vars.$semantic.typography.caption2Regular}
  `,
};

export default ArticleCardStyle;
