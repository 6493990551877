import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { IconChevronRightLine } from '@daangn/react-monochrome-icon';
import { vars } from '@seed-design/design-token';
import Text from 'components/Base/Text';
import { useAppPreloadedQuery } from 'contexts/AppPreloadedContext';
import useCustomFlow from 'hooks/useCustomFlow';
import { AllRecap } from 'pages/Recap';
import { graphql, useFragment } from 'react-relay';
import { Result_userYearReport$key } from '__generated__/Result_userYearReport.graphql';
import ArticleCarouselItem from 'components/Article/ArticleCarouselItem';
import Logger from 'utils/Logger';
import { Stack } from 'components/Base/Stack/Stack';
import { HStack } from 'components/Base/Stack/HStack';

export default function Result({
  userYearReportRef,
}: {
  userYearReportRef: Result_userYearReport$key;
}) {
  const { push } = useCustomFlow();
  const { viewer } = useAppPreloadedQuery();

  const userYearReport = useFragment(
    graphql`
      fragment Result_userYearReport on UserYearReport {
        readArticleCount
        countPercentile
        tags
        recommendArticles {
          id
          ...ArticleCarouselItem_article
        }
      }
    `,
    userYearReportRef
  );

  const contents = useMemo(() => {
    if (userYearReport.readArticleCount >= 5)
      return {
        title: (
          <>
            {viewer?.nickname}님과 이웃들의 <br />
            2024 당근부동산 여정을 <br />
            함께 살펴봤어요!
          </>
        ),
        subtitle1: (
          <>
            {viewer?.nickname}님은 총 {userYearReport.readArticleCount}개의 <br />
            매물을 조회한 <ColoredText>상위 {userYearReport.countPercentile}%</ColoredText>{' '}
            이웃이에요
          </>
        ),
        subtitle2: <>{viewer?.nickname}님의 최애 유형은 다음과 같아요</>,
        tags: userYearReport.tags,
        subtitle3: <>{viewer?.nickname}님이 놓쳤던 관심 매물 🧡</>,
        recommendArticles: userYearReport.recommendArticles,
      };
    else
      return {
        title: (
          <>
            이웃들의 <br />
            2024 당근부동산 여정을 <br />
            함께 살펴봤어요!
          </>
        ),
        subtitle1: <>이웃들은 평균 {AllRecap.readArticleCount}개의 매물을 조회했어요</>,
        tags: AllRecap.tags,
        subtitle2: <>이웃들의 최애 유형은 다음과 같아요</>,
        subtitle3: <>우리 동네 추천 매물 🧡</>,
        recommendArticles: userYearReport.recommendArticles,
      };
  }, [viewer]);

  const handleClickSeeDanggnRealty = () => {
    Logger.track('click_recap_more_button', {});
    push('main', {});
  };

  return (
    <Wrapper>
      <div className="px-5">
        <div className="mt-3 flex flex-col items-center gap-4">
          <img
            src="https://asset-town.krrt.io/production/illust/a573bcfd-d798-4d3e-bdbb-c1b4921649d2/7caaaceca8a2efb450e5a688f38a6d174d2e1e40.webp"
            width={64}
            height={64}
          />

          <Text variant="title2Bold" textAlign="center" className="line-break-text">
            {contents.title}
          </Text>
        </div>

        <Stack spacing={12} className="mt-6">
          {/* 매물 조회수 */}
          <ResultBox>
            <Text variant="subtitle1Bold">{contents.subtitle1}</Text>
          </ResultBox>

          {/* 매물 유형 태그 */}
          <ResultBox spacing={4}>
            <Text variant="subtitle1Bold">{contents.subtitle2}</Text>
            <Text variant="bodyL2Regular">{contents.tags.map((tag) => `#${tag}`).join(', ')}</Text>
          </ResultBox>
        </Stack>

        {/* 관심 매물 */}
        {contents.recommendArticles.length > 0 && (
          <Stack spacing={16} className="mt-[51px]">
            <div className="flex justify-between">
              <Text variant="subtitle1Bold">{contents.subtitle3}</Text>
              <IconChevronRightLine width={16} height={16} onClick={handleClickSeeDanggnRealty} />
            </div>

            <HStack spacing={10} className="horizontal-scroll">
              {contents.recommendArticles.map((article, index) => (
                <ArticleCarouselItem
                  key={article.id}
                  index={index}
                  articleRef={article}
                  section="realty_recap"
                />
              ))}
            </HStack>
          </Stack>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;

  overflow: scroll;
`;

const ResultBox = styled(Stack)`
  border-radius: 12px;
  background: ${vars.$static.color.staticWhiteAlpha200};
  padding: 16px 20px;
`;

const ColoredText = styled.span`
  color: ${vars.$semantic.color.primary};
`;
