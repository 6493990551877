import { IconILowercaseSerifCircleLine } from '@daangn/react-monochrome-icon';
import AdvertisementImpressionConditionBottomSheet from 'components/Article/AdvertisementImpressionConditionBottomSheet';
import ActionSheet from 'components/Base/ActionSheet';
import { HStack } from 'components/Base/Stack/HStack';
import useDisclosure from 'hooks/useDisclosure';
import Logger from 'utils/Logger';

type Props = {
  articleId: string;
  onHideAdvertise: () => void;
};

const ArticleAdvertisementHelpButton = ({ articleId, onHideAdvertise }: Props) => {
  const [isAdvertiseSheetOpen, advertiseSheetHandler] = useDisclosure();
  const [isImpressionConditionSheetOpen, impressionConditionSheetHandler] = useDisclosure();

  const handleAdvertiseHelpClick = () => {
    Logger.track('click_ad_help', { article_id: articleId });

    advertiseSheetHandler.onOpen();
  };

  const handleHideAdvertiseClick = () => {
    Logger.track('click_ad_hide', { article_id: articleId });

    onHideAdvertise();
  };

  const handleImpressionConditionClick = () => {
    Logger.track('click_ad_impression_condition', { article_id: articleId });

    advertiseSheetHandler.onClose();
    impressionConditionSheetHandler.onOpen();
  };

  return (
    <div className="inline-flex" onClick={(e) => e.stopPropagation()}>
      <div onClick={handleAdvertiseHelpClick}>
        <HStack spacing={2}>
          <IconILowercaseSerifCircleLine width={15} height={15} />
          <span>광고</span>
        </HStack>
      </div>
      <ActionSheet
        isOpen={isAdvertiseSheetOpen}
        onClose={advertiseSheetHandler.onClose}
        actions={[
          {
            label: '이 광고 그만보기',
            onClick: handleHideAdvertiseClick,
          },
          {
            label: '게시글 노출 기준',
            onClick: handleImpressionConditionClick,
          },
          {
            label: '취소',
          },
        ]}
      />
      <AdvertisementImpressionConditionBottomSheet
        isOpen={isImpressionConditionSheetOpen}
        onClose={impressionConditionSheetHandler.onClose}
      />
    </div>
  );
};

export default ArticleAdvertisementHelpButton;
