import '@daangn/transfer-identification-sdk/index.css';

import { Iframe, useVerifyIdentification } from '@daangn/transfer-identification-sdk';
import { karrotBridge } from 'bridge';
import CONFIG, { IS_PROD } from 'config';
import { useMemo, useRef, useState } from 'react';
import { actionToast } from 'utils/actionToast';
import { getUser } from 'bridge/info';
import { createFetch } from 'plantae';
import authPlugin from '@daangn/plantae-plugin-auth';
import { useOverlay } from '@toss/use-overlay';
import BottomSheet from 'components/BottomSheet/BottomSheet';
import { css } from '@emotion/react';
import ButtonGroup from 'components/Base/ButtonGroup';
import BoxButton from 'components/Base/BoxButton';
import Text from 'components/Base/Text';
import SubButton from 'components/Base/SubButton';
import LazyLottiePlayer from 'components/Base/LazyLottiePlayer';
import { fetchQuery, graphql } from 'relay-runtime';
import MainEnvironment from 'relay/environment';
import { useIdentificationQuery } from '__generated__/useIdentificationQuery.graphql';
import { readUserIdentification } from 'utils/user';
import { captureException } from '@sentry/react';
import Logger from 'utils/Logger';
import { Stack } from 'components/Base/Stack/Stack';

export type IdentificationType = 'dev' | 'write' | 'bizProfileOwnerVerification';

type Props = {
  type: IdentificationType;
  title?: string;
  description?: string;
};

const IdentificationConfig = {
  previousScreenName: 'realty',
  serviceName: `${CONFIG.BRAND_NAME} ${CONFIG.SERVICE_NAME}`,
};

const fetchUserIdentification = async () => {
  const user = await fetchQuery<useIdentificationQuery>(
    MainEnvironment,
    graphql`
      query useIdentificationQuery {
        viewer {
          ...userIdentification_user
        }
      }
    `,
    {}
  )
    .toPromise()
    .catch(() => null);

  if (!user) {
    return null;
  }

  return readUserIdentification(user.viewer);
};

const IDENTIFICATION_API_URL = `${CONFIG.IDENTIFICATION_HOST}/identification/v2/karrot-users/me/kr/identification`;

export type IdentificationResult =
  | {
      success: true;
      result: Exclude<ReturnType<typeof readUserIdentification>, null | undefined>;
    }
  | {
      success: false;
    };

const useIdentification = ({
  type,
  title = '부동산 거래가 처음이라면\n1회의 본인인증이 필요해요',
  description = '본인인증은 안전한\n거래를 위한 첫걸음이에요',
}: Props) => {
  const resolveRef = useRef<
    ((value: PromiseLike<IdentificationResult> | IdentificationResult) => void) | undefined
  >(undefined);

  const envProps = useMemo(() => {
    return IS_PROD
      ? {
          agreementUrl: 'https://terms.karrotwebview.com/pay_identity/',
          transferIdentificationScheme: `karrot://minikarrot/router?remote=https%3A%2F%2Ftransfer-identification.karrotwebview.com%2F%3Fprevious_screen_name%3D${IdentificationConfig.previousScreenName}&navbar=false&scrollable=false`,
          newIdentificationScheme: `karrot://identification?type=account&referrer=${IdentificationConfig.previousScreenName}&show_complete=true`,
        }
      : {
          agreementUrl: 'https://terms.alpha.karrotwebview.com/pay_identity/',
          transferIdentificationScheme: `karrot.alpha://minikarrot/router?remote=https%3A%2F%2Ftransfer-identification.alpha.karrotwebview.com%2F%3Fprevious_screen_name%3D${IdentificationConfig.previousScreenName}&navbar=false&scrollable=false`,
          newIdentificationScheme: `karrot.alpha://identification?type=account&referrer=${IdentificationConfig.previousScreenName}&show_complete=true`,
        };
  }, []);

  const identificationOverlay = useOverlay();
  const agreementOverlay = useOverlay();

  const fetchVerify = async () => {
    const defaultUser = await getUser();

    const fetchWithAuth = createFetch({
      plugins: [
        authPlugin({
          bridge: karrotBridge,
          options: {
            fallbackAuthToken: {
              value: defaultUser?.authToken ?? '',
            },
          },
        }),
      ],
    });
    return fetchWithAuth(IDENTIFICATION_API_URL);
  };

  const reset = () => {
    resolveRef.current = undefined;
    identificationOverlay.close();
    agreementOverlay.close();
  };

  const handleNotIdentified = () => {
    Logger.track('user_identification_failed', { type });
    resolveRef.current?.({ success: false });
    reset();
  };

  const handleIdentified = async () => {
    Logger.track('user_identification_success', { type });

    const userIdentification = await fetchUserIdentification();

    resolveRef.current?.(
      !!userIdentification ? { success: true, result: userIdentification } : { success: false }
    );
    reset();
  };

  const handleError = (e: Error) => {
    Logger.track('user_identification_failed', { type });

    captureException(e);
    actionToast({
      message: '일시적인 오류가 발생했어요. 다시 시도해 주세요.',
    });
    resolveRef.current?.({ success: false });
    reset();
  };

  const handleMoveAgreement = () => {
    Logger.track('user_identification_move_agreement', { type });

    agreementOverlay.open(({ isOpen, close }) => {
      const handleClose = () => {
        close();
        resolveRef.current?.({ success: false });
        reset();
      };

      return (
        <AgreementBottomSheet isOpen={isOpen} onClose={handleClose} url={envProps.agreementUrl} />
      );
    });
  };

  const checkAndCreateIdentification = () => {
    return new Promise<IdentificationResult>(async (resolve) => {
      const userIdentification = await fetchUserIdentification();

      if (!!userIdentification) {
        resolve({ success: true, result: userIdentification });
        reset();
        return;
      }

      Logger.track('start_user_identification', { type });

      resolveRef.current = resolve;

      identificationOverlay.open(({ isOpen, close }) => {
        const handleClose = () => {
          Logger.track('close_user_identification', { type });
          close();
          reset();
        };

        return (
          <IdentificationBottomSheet
            isOpen={isOpen}
            onClose={handleClose}
            title={title}
            description={description}
            identificationProps={{
              ...envProps,
              bridge: karrotBridge,
              fetchVerify,
              onError: handleError,
              onIdentified: handleIdentified,
              onNotIdentified: handleNotIdentified,
              onMoveAgreement: handleMoveAgreement,
            }}
          />
        );
      });
    });
  };

  return {
    checkAndCreateIdentification,
  };
};

export default useIdentification;

type AgreementBottomSheetProps = {
  isOpen: boolean;
  onClose: () => void;
  url: string;
};

function AgreementBottomSheet({ isOpen, onClose, url }: AgreementBottomSheetProps) {
  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={false}
      css={css`
        padding-bottom: 0;
      `}
      contentProps={{
        style: {
          padding: 0,
        },
      }}
    >
      <Iframe bridge={karrotBridge} url={url} />
    </BottomSheet>
  );
}

type IdentificationBottomSheetProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  identificationProps: Parameters<typeof useVerifyIdentification>[0];
};
function IdentificationBottomSheet({
  isOpen,
  onClose,
  title,
  description,
  identificationProps,
}: IdentificationBottomSheetProps) {
  const [shouldHide, setShouldHide] = useState(false);
  const { onNextClick } = useVerifyIdentification(identificationProps);

  const handleClickVerify = () => {
    setShouldHide(true);
    onNextClick();
  };

  return (
    <BottomSheet
      isOpen={isOpen && !shouldHide}
      onClose={onClose}
      showCloseButton={false}
      footer={
        <ButtonGroup direction="column">
          <BoxButton width="100%" size="xlarge" onClick={handleClickVerify}>
            30초 만에 인증하기
          </BoxButton>
          <SubButton onClick={onClose}>다음에 할게요</SubButton>
        </ButtonGroup>
      }
    >
      <div className="pt-7">
        <div className="mx-auto h-[150px] w-[150px]">
          <LazyLottiePlayer
            loop
            autoplay
            path="https://asset-town.krrt.io/production/motion/2e7f1e7f-a3ac-4f9b-833f-6fc97af948bf/972c12a34de6bad958e68f71d1b544b07efb7c35.json"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <Stack spacing={12} className="whitespace-pre-wrap px-1 py-6 text-center">
          <Text variant="title2Bold">{title}</Text>
          <Text variant="bodyL1Regular">{description}</Text>
        </Stack>
      </div>
    </BottomSheet>
  );
}
