import type { userIdentification_user$key } from '__generated__/userIdentification_user.graphql';
import { graphql, readInlineData } from 'relay-runtime';

export const readUserIdentification = (userRef: userIdentification_user$key | null) => {
  const user = readInlineData(
    graphql`
      fragment userIdentification_user on KarrotUser @inline {
        id
        karrotUserIdentification {
          birthDate {
            year
            month
            day
          }
          di
          gender
          karrotUserId
          phoneNumber
          realName
          verifyTime
        }
      }
    `,
    userRef
  );

  return user?.karrotUserIdentification;
};
