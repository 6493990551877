import type { useAdvertisementInfo_advertisementInfo$key } from '__generated__/useAdvertisementInfo_advertisementInfo.graphql';
import type { useArticleItemEventParams_article$key } from '__generated__/useArticleItemEventParams_article.graphql';
import { ArticleItemSections, type ArticleItemSection } from 'constants/section';
import { useAdvertisementItem } from 'hooks/useAdvertisementItem';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { isViewedArticleState } from 'store/viewedArticle';

type Params = {
  index: number;
  section: ArticleItemSection;
  advertisementInfoRef?: useAdvertisementInfo_advertisementInfo$key | null;
};

const useArticleItemEventParams = (
  articleRef: useArticleItemEventParams_article$key,
  params: Params
) => {
  const { advertisementInfoRef, index, section } = params;
  const article = useFragment(
    graphql`
      fragment useArticleItemEventParams_article on Article {
        id
        originalId
        isWriterVerify
        writerType
      }
    `,
    articleRef
  );
  const advertisementItem = useAdvertisementItem(advertisementInfoRef ?? null, section);
  const isViewed = useAtomValue(isViewedArticleState(article.originalId));
  const itemType = useMemo(() => {
    return Object.entries(ArticleItemSections).find(([, sections]) =>
      sections.some((v) => v === section)
    )?.[0];
  }, [section]);

  const eventParams = {
    article_id: article.originalId,
    is_verified: article.isWriterVerify,
    is_watched: isViewed,
    is_advertisement: !!advertisementItem,
    placement_id: advertisementItem?.placementId,
    writer_type: article.writerType,
    item_type: itemType,
    section,
    index,
  };

  return eventParams;
};

export default useArticleItemEventParams;
