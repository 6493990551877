import type { LottiePlayerProps } from 'components/Base/LottiePlayer';
import { Suspense, lazy } from 'react';

const LottiePlayer = lazy(() => import('components/Base/LottiePlayer'));

const LazyLottiePlayer = (props: LottiePlayerProps) => {
  return (
    <Suspense fallback={<div style={props.style} />}>
      <LottiePlayer {...props} />
    </Suspense>
  );
};

export default LazyLottiePlayer;
