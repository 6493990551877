import Dim from 'components/Base/Dim';
import PageNavPortal from 'components/Base/Portal/PageNavPortal';
import PageContentPortal from 'components/Base/Portal/PageContentPortal';
import { zIndices } from 'constants/zIndices';
import { PropsWithChildren, createContext, useContext } from 'react';
import { appBarMargin } from '../Container';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDimClick?: () => void;
  closeOnDimClick?: boolean;
};

const ModalContext = createContext<Props>({
  isOpen: false,
  onClose: () => undefined,
});

const PageLevelModal = ({
  isOpen,
  onClose,
  onDimClick,
  closeOnDimClick = true,
  children,
}: PropsWithChildren<Props>) => {
  const handleDimClick = () => {
    if (closeOnDimClick) {
      onClose();
    }
    onDimClick?.();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        onClose,
        onDimClick: handleDimClick,
        closeOnDimClick,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const PageLevelDim = () => {
  const { onDimClick } = useContext(ModalContext);

  return (
    <>
      <PageNavPortal>
        <Dim key="navdim" onClick={onDimClick} />
      </PageNavPortal>
      <PageContentPortal>
        <Dim
          key="pagedim"
          onClick={onDimClick}
          style={{
            top: appBarMargin,
          }}
        />
      </PageContentPortal>
    </>
  );
};

const PageLevelHeader = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <PageNavPortal>
      <div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          zIndex: zIndices.overlay + 1,
        }}
      >
        {children}
      </div>
    </PageNavPortal>
  );
};

const PageLevelBody = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <PageContentPortal>
      <div className="absolute bottom-0 left-0 right-0 top-0 overflow-hidden">
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: zIndices.overlay + 1,
          }}
        >
          {children}
        </div>
      </div>
    </PageContentPortal>
  );
};

PageLevelModal.Dim = PageLevelDim;
PageLevelModal.Header = PageLevelHeader;
PageLevelModal.Body = PageLevelBody;

export default PageLevelModal;
