import { Stack } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { useAppPreloadedQuery } from 'contexts/AppPreloadedContext';
import { graphql, useFragment } from 'react-relay';
import { Step1_userYearReport$key } from '__generated__/Step1_userYearReport.graphql';

export default function Step1({
  userYearReportRef,
}: {
  userYearReportRef: Step1_userYearReport$key;
}) {
  const { viewer } = useAppPreloadedQuery();

  const userYearReport = useFragment(
    graphql`
      fragment Step1_userYearReport on UserYearReport {
        readArticleCount
      }
    `,
    userYearReportRef
  );

  const hasData = useMemo(() => {
    return userYearReport.readArticleCount >= 5;
  }, []);

  return (
    <Wrapper>
      <TextWrapper gap={16} px={20}>
        <Text variant="title1Bold">
          2024년 1,600만 명의 이웃이
          <br /> 당근부동산을 방문했어요
        </Text>
        <Text variant="title2Bold">
          {hasData && <>{viewer?.nickname}님과 </>}
          이웃들은 <br />
          당근부동산을 어떻게 이용했을까요?
        </Text>
      </TextWrapper>

      <ImageWrapper>
        <img
          src="https://assetstorage.krrt.io/1420322515413545053/9cd8805f-86cc-4d85-88ae-e63b58f00ee9/width=750,height=752.webp"
          width="100%"
        />
        <AssetImage
          src="https://asset-town.krrt.io/production/motion/a248fb12-4c66-4c2a-a971-fcd2a07f9a36/a1c3bdbf2579ba5e8fa3d7adf155d90dc4cad817.png"
          width={200}
          height={200}
        />
      </ImageWrapper>
    </Wrapper>
  );
}

const TextWrapper = styled(Stack)`
  position: relative;
  z-index: 100;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const AssetImage = styled.img`
  position: absolute;
  bottom: -33px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
`;
