import { Center } from '@chakra-ui/layout';
import { vars } from '@seed-design/design-token';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import Text from 'components/Base/Text';
import BottomSheet from 'components/BottomSheet/BottomSheet';
import { Stack } from 'components/Base/Stack/Stack';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const AdvertisementImpressionConditionBottomSheet = ({ isOpen, onClose }: Props) => {
  return (
    <BottomSheet showCloseButton={false} isOpen={isOpen} onClose={onClose}>
      <button className="absolute right-4 top-5 inline-block p-0 text-gray900" onClick={onClose}>
        <IconXmarkLine width={24} height={24} />
      </button>
      <Center
        h="170px"
        bg={vars.$scale.color.gray200}
        marginLeft={-16}
        marginRight={-16}
        alignItems="flex-end"
      >
        <img src="https://assetstorage.krrt.io/1138369647032855545/acbab5de-17a6-48ed-897d-58fc07f564bc/width=187,height=144.svg" />
      </Center>
      <Stack spacing={6} className="px-4 pb-4 pt-6">
        <Text variant="title2Bold">광고 노출 기준</Text>
        <Text variant="subtitle1Regular">
          앱 내 최근 활동 이력을 분석하여 이용자의 관심사와 관련성이 높은 게시글을 노출해요.
        </Text>
      </Stack>
    </BottomSheet>
  );
};

export default AdvertisementImpressionConditionBottomSheet;
