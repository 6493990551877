import { forwardRef, HTMLAttributes } from 'react';
import { Stack, StackProps } from './Stack';
import { cn } from 'styles/utils';

type HStackProps = Omit<StackProps, 'direction'> & HTMLAttributes<HTMLDivElement>;

export const HStack = forwardRef<HTMLDivElement, HStackProps>(({ className, ...props }, ref) => (
  <Stack direction="row" className={cn('items-center', className)} {...props} ref={ref} />
));

HStack.displayName = 'HStack';
