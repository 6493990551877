import * as Sentry from '@sentry/react';
import CONFIG, { IS_LOCAL } from 'config';
import { checkIsRelayNetworkError } from 'utils/relay';

if (!IS_LOCAL) {
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    ignoreErrors: [
      // https://daangn.slack.com/archives/CUV91G2MT/p1671546088048909?thread_ts=1671501341.168479&cid=CUV91G2MT
      'Java exception was raised during method invocation',
      // https://daangn.slack.com/archives/CGDR2PPM2/p1710897800329639
      'Java object is gone',
    ],
    environment: process.env.REACT_APP_STAGE,
    release: process.env.REACT_APP_SENTRY_RELEASE_TARGET,
    normalizeDepth: 6,
    beforeSend(event, hint) {
      const originalError = hint.originalException as Error;
      const isRelayNetworkError = checkIsRelayNetworkError(originalError);

      if (isRelayNetworkError && originalError?.source?.errors?.[0]?.extensions?.sentryHandled) {
        return null;
      }

      return event;
    },
  });
}
