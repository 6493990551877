import ArticleDetailPageSkeleton from 'components/Base/Skeleton/ArticleDetailPageSkeleton';
import MainPageSkeleton from 'components/Base/Skeleton/MainPageSkeleton';
import { RouteName } from 'routes';
import { getCurrentPageRouteName } from 'stackflow/utils';

const PageSkeletonMap: Partial<Record<RouteName, () => JSX.Element>> = {
  main: MainPageSkeleton,
  article_detail: ArticleDetailPageSkeleton,
};

export const getCurrentPageSkeleton = () => {
  const routename = getCurrentPageRouteName();

  if (!routename) {
    return null;
  }

  return PageSkeletonMap[routename] ?? null;
};
