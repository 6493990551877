import { css } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import { SectionContainer } from 'components/Base/Container';
import Placeholder from 'components/Base/Skeleton/Placeholder';
import { Stack } from 'components/Base/Stack/Stack';
import { HStack } from 'components/Base/Stack/HStack';

const ArticleDetailPageSkeleton = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        background: vars.$semantic.color.paperDefault,
      }}
    >
      <Placeholder.Box
        css={css`
          width: 100%;
          height: 100vw;
        `}
      />
      <SectionContainer>
        <Stack spacing={16} divider={<hr className="border-divider2" />} className="pt-5">
          <HStack spacing={12}>
            <Placeholder.Box
              css={css`
                width: 48px;
                height: 48px;
                border-radius: 100%;
              `}
            />
            <Stack spacing={2}>
              <Placeholder.Text css={vars.$semantic.typography.subtitle1Bold}>
                당근마켓
              </Placeholder.Text>
              <Placeholder.Text css={vars.$semantic.typography.label3Regular}>
                서초동
              </Placeholder.Text>
            </Stack>
          </HStack>
          <Stack spacing={8}>
            <Stack spacing={4}>
              <Placeholder.Text css={vars.$semantic.typography.bodyM2Regular}>
                오픈형 원룸
              </Placeholder.Text>
              <Placeholder.Text css={vars.$semantic.typography.title1Bold}>
                월세 500/40
              </Placeholder.Text>
            </Stack>
            <div className="py-3">
              <Placeholder.Text
                css={css`
                  width: 100%;
                  border-radius: 10px;
                  padding: 14px;
                  border: 1px solid transparent;
                  ${vars.$semantic.typography.label3Bold}
                `}
              >
                직거래로 아끼는 비용
              </Placeholder.Text>
            </div>
            <div>
              <Placeholder.Text css={vars.$semantic.typography.subtitle2Regular}>
                8일 전 · 채팅 0 · 관심 0 · 조회 7
              </Placeholder.Text>
            </div>
          </Stack>
        </Stack>
      </SectionContainer>
    </div>
  );
};

export default ArticleDetailPageSkeleton;
