const SafeAreaCssVariable = {
  top: '--sat',
  right: '--sar',
  bottom: '--sab',
  left: '--sal',
};

type SafeAreaCssVariableName = keyof typeof SafeAreaCssVariable;

const pxToNumber = (pxString: string) => {
  return Number(pxString.match(/\d+/) ?? 0);
};

const getDeviceFontScale = () => {
  const span = document.createElement('span');
  const initFontSize = 16;
  span.className = 'visually-hidden';
  span.style.fontSize = `${initFontSize}px`;

  document.body.appendChild(span);
  const scaledFontSize = pxToNumber(getComputedStyle(span).getPropertyValue('font-size'));
  const scale = scaledFontSize / initFontSize;
  const isValid = !!scale && !Number.isNaN(scale);
  document.body.removeChild(span);

  return isValid ? scale : 1;
};

export const DEVICE_FONT_SCALE = getDeviceFontScale();

export const getSafeArea = () => {
  return Object.entries(SafeAreaCssVariable).reduce(
    (acc, [key, value]) => {
      const pxString = getComputedStyle(document.documentElement).getPropertyValue(value);

      acc[key as SafeAreaCssVariableName] = pxToNumber(pxString);

      return acc;
    },
    {} as { [key in SafeAreaCssVariableName]: number }
  );
};

const getBodyFontSize = () => {
  return pxToNumber(window.getComputedStyle(document.body).getPropertyValue('font-size'));
};

export const checkIsBigFont = () => {
  return getBodyFontSize() >= 16 * 1.5;
};
