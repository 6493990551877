import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BOTTOM_FIXED_CLASS_NAME } from 'components/Base/BottomFixed';
import { checkIsBigFont } from 'utils/style';

export const bottomFixedAwarePadding = css`
  padding-bottom: calc(${checkIsBigFont() ? '120px' : '80px'} + env(safe-area-inset-bottom));
`;

/* TODO: seed에서 cssVars 제공되면 변경 예정 */
export const appBarMargin = `var(--app-bar-margin, 0)`;

export const PageContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
`;

export const ScrollContainer = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;

  &:has(.${BOTTOM_FIXED_CLASS_NAME}) {
    ${bottomFixedAwarePadding}
  }
`;

export const PaddingContainer = styled.div`
  padding: 16px 0px;
  margin: 0px 16px;
`;

export const SectionContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;
