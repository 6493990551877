import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { SECTION_NO_DIVIDER_CLASS_NAME } from 'pages/ArticleDetail/components/SectionNoDivider';

type DividerType = 'base' | 'nav';

const Divider = styled.hr<{ type?: DividerType; rounded?: boolean; hideOnEdge?: boolean }>`
  border: 0;

  & + & {
    display: none;
  }

  ${({ type }) =>
    type === 'base'
      ? `
      border-bottom: 1px solid ${vars.$semantic.color.divider1};
    `
      : `
      border-bottom: 1px solid ${vars.$semantic.color.divider2};
      transform: scaleY(0.5);
    `}

  ${({ rounded }) =>
    rounded &&
    `
    border-radius: 24px 24px 0px 0px;
    `}

  ${({ hideOnEdge }) =>
    hideOnEdge &&
    `
    &:first-child {
      display: none;
    }
    &:last-child {
      display: none;
    }
  `}
`;

export const SectionStackDivider = styled.hr<{ hideOnEdge?: boolean }>`
  border-color: ${vars.$semantic.color.divider2};

  & + & {
    display: none;
  }

  .${SECTION_NO_DIVIDER_CLASS_NAME} + & {
    display: none;
  }

  ${({ hideOnEdge }) =>
    hideOnEdge &&
    `
    &:first-child {
      display: none;
    }
    &:last-child {
      display: none;
    }
  `}
`;

export default Divider;
