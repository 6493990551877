import { HStack, Spacer, Stack } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import ArticleCardStyle from 'components/Article/Card/ArticleCardStyle';
import PlaceHolder from 'components/Base/Skeleton/Placeholder';

const ArticleCardSkeleton = () => {
  return (
    <ArticleCardStyle.Container>
      <ArticleCardStyle.Body spacing={16}>
        <PlaceHolder.Box
          css={css`
            width: 118px;
            height: 118px;
            flex-shrink: 0;
            border-radius: 6px;
          `}
        />
        <ArticleCardStyle.Content spacing={6}>
          <Stack spacing={2}>
            <PlaceHolder.Text>월세 1000</PlaceHolder.Text>
            <Stack spacing={4}>
              <ArticleCardStyle.SubTitle>
                <PlaceHolder.Text>아파트 10평 10층</PlaceHolder.Text>
              </ArticleCardStyle.SubTitle>
              <ArticleCardStyle.Information>
                <PlaceHolder.Text>관리비 없음</PlaceHolder.Text>
              </ArticleCardStyle.Information>
            </Stack>
          </Stack>
          <Stack
            spacing={4}
            css={css`
              flex-grow: 1;
            `}
          >
            <Spacer />
            <HStack>
              <ArticleCardStyle.DateText>
                <PlaceHolder.Text>3일 전</PlaceHolder.Text>
              </ArticleCardStyle.DateText>
              <Spacer />
              <PlaceHolder.Text css={vars.$semantic.typography.caption1Regular}>
                채팅 0 관심 0
              </PlaceHolder.Text>
            </HStack>
          </Stack>
        </ArticleCardStyle.Content>
      </ArticleCardStyle.Body>
    </ArticleCardStyle.Container>
  );
};

export default ArticleCardSkeleton;
