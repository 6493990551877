import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { zIndices } from 'constants/zIndices';

const BaseThumbnail = styled.div`
  position: relative;
  flex-shrink: 0;

  border: 1px solid ${vars.$scale.color.grayAlpha50};
  border-radius: 6px;

  overflow: hidden;
  z-index: ${zIndices.docked};

  & img {
    width: 100%;
    height: 100%;
  }
`;

export type ThumbnailProps = {
  width?: string | number;
  height?: string | number;
};

const Thumbnail = styled(BaseThumbnail)<ThumbnailProps>`
  ${({ width, height }) => {
    const imgWidth = width || 118;
    const imgHeight = height || 118;

    return css`
      width: ${imgWidth}px;
      height: ${imgHeight}px;
    `;
  }}
`;

export type ThumbnailRatioProps = {
  ratio: '2/1' | '16/9' | '4/3' | '1/1';
};

export const ThumbnailRatio = styled(BaseThumbnail)<ThumbnailRatioProps>`
  aspect-ratio: ${({ ratio }) => ratio};
`;

export default Thumbnail;
