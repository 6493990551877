import { HStack } from '@chakra-ui/layout';
import { css } from '@emotion/react';
import { vars } from '@seed-design/design-token';
import MainSectionSkeleton from 'components/Base/Skeleton/MainSectionSkeleton';
import PlaceHolder from 'components/Base/Skeleton/Placeholder';

const MainPageSkeleton = () => {
  return (
    <div>
      <div className="px-4 py-2">
        <HStack spacing={4}>
          <PlaceHolder.Text
            css={css`
              width: 100%;
              ${vars.$semantic.typography.label3Regular}
              border-radius: 6px;
              padding: 8px 12px;
              min-height: 40px;
            `}
          >
            서울특별시 관악구
          </PlaceHolder.Text>
          <PlaceHolder.Box
            css={css`
              flex-shrink: 0;
              width: 42px;
              height: 40px;
              border-radius: 6px;
            `}
          />
        </HStack>
      </div>
      <div className="px-4">
        <MainSectionSkeleton />
      </div>
    </div>
  );
};

export default MainPageSkeleton;
