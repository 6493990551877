import React, { useEffect } from 'react';
import { Center, Flex } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import styled from '@emotion/styled';
import { motion, useAnimation } from 'framer-motion';
import { AllRecap } from '..';
import { Stack } from 'components/Base/Stack/Stack';

const images = [
  'https://assetstorage.krrt.io/1420322515413545053/9b4b7324-d950-420b-b57f-0f8faa7bca77/width=288,height=288.webp',
  'https://assetstorage.krrt.io/1420322515413545053/465a7d23-678c-4436-be1d-87105b65a510/width=288,height=288.webp',
  'https://assetstorage.krrt.io/1420322515413545053/2a8981c6-238f-4e47-bd49-fdff22d06d65/width=336,height=336.webp',
  'https://assetstorage.krrt.io/1420322515413545053/2cabe33f-90f0-4ef7-8159-256e6a1ef96c/width=336,height=336.webp',
  'https://assetstorage.krrt.io/1420322515413545053/f5e7a0c2-ac5f-4df8-951d-817c1b167bf5/width=288,height=288.webp',
  'https://assetstorage.krrt.io/1420322515413545053/8e29e2a8-9435-430a-9582-eb016135a4bf/width=288,height=288.webp',
];
export default function Step3() {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      x: ['0%', '-100%'],
      transition: {
        repeat: Infinity,
        repeatType: 'loop',
        duration: 15,
        ease: 'linear',
      },
    });
  }, [controls]);

  return (
    <Stack className="relative h-full w-full overflow-hidden">
      <div className="px-5">
        <Text variant="title1Bold">
          올해 이웃들은 <br />
          평균 {AllRecap.readArticleCount}개의 매물을 조회했어요
        </Text>
      </div>

      <Center flexDirection="column" height="80%" justifyContent="center">
        <ImageWrapper gap={16} as={motion.div} animate={controls}>
          {images.map((img, idx) => (
            <Image src={img} key={idx} />
          ))}
        </ImageWrapper>
      </Center>
    </Stack>
  );
}

const ImageWrapper = styled(Flex)`
  width: 100%;
  padding-left: 20px;
`;

const Image = styled.img`
  width: 140px;
  height: 140px;
`;
