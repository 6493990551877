import { useActivity } from '@stackflow/react';
import { AppBarProps, usePageContext } from 'components/Base/PageLayout';
import { useLayoutEffect } from 'react';
import { RouteName } from 'routes';
import { defaultScreenProps } from 'stackflow/components/DefaultScreenProps';

const ScreenHelmet = (props: AppBarProps & { preventSwipeBack?: boolean }) => {
  const { setScreenOptions } = usePageContext();
  const { name } = useActivity();

  const defaultScreenOptions = defaultScreenProps[name as RouteName];
  if (defaultScreenOptions?.withoutAppBar && defaultScreenOptions.appBar) {
    throw new Error(
      'withoutAppBar 옵션을 true로 설정한 경우, appBar 관련 속성을 사용할 수 없어요.'
    );
  }

  useLayoutEffect(() => {
    const { preventSwipeBack, ...appBar } = props;

    setScreenOptions((prev) => ({
      ...prev,
      preventSwipeBack,
      appBar,
    }));
  }, [props]);

  return null;
};

export default ScreenHelmet;
