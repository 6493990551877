import {
  BuildingNameAvailableSalesTypes,
  OneRoomSalesTypes,
  ResidentSalesTypes,
  SalesTypeEnum,
  isOneOfSalesType,
} from '@daangn/realty-sdk';
import { FormArticleType } from 'types/Article';
import {
  addressSchema,
  areaInfoSchema,
  areaSchema,
  buildingApprovalDateSchema,
  buildingNameSchema,
  buildingUsageSchema,
  dongHoSchema,
  floorSchema,
  imagesSchema,
  manageCostSchema,
  moveInDateSchema,
  orientationSchema,
  premiumMoneySchema,
  qualitativeSchema,
  requiredOptionSchema,
  roomSchema,
  salesTypeSchema,
  supplyAreaSchema,
  tradesSchema,
  writerTypeSchema,
} from 'utils/validate';
import type { SafeParseReturnType } from 'zod';

export type ArticleField = {
  title: string;
  validate?: (form: FormArticleType) => SafeParseReturnType<unknown, unknown>;
  isRequired?: (form: FormArticleType) => boolean;
  include?: (form: Pick<FormArticleType, 'salesType' | 'writerType'>) => boolean;
};

export enum ArticleFieldKey {
  WriterType = 'writerType',
  BuildingUsage = 'buildingUsage',
  SalesType = 'salesType',
  Address = 'address',
  AddressInfo = 'addressInfo',
  DongHo = 'dongHo',
  Trade = 'trade',
  PremiumMoney = 'premiumMoney',
  Image = 'image',
  Video = 'video',
  Orientation = 'orientation',
  AreaInfo = 'areaInfo',
  Area = 'area',
  SupplyArea = 'supplyArea',
  Floor = 'floor',
  Room = 'room',
  BuildingApprovalDate = 'buildingApprovalDate',
  BuildingName = 'buildingName',
  ManageCost = 'manageCost',
  RequiredOption = 'requiredOption',
  MoveInDate = 'moveInDate',
  Option = 'option',
  Qualitative = 'qualitative',
  Description = 'description',
}

const isBroker = (form: Pick<FormArticleType, 'writerType'>) => form.writerType === 'BROKER';

// 현재 중개사는 거주 타입만 등록 가능하며, 중개사 AI 옮겨쓰기 시 매물 타입값 없이도 Summary에 해당 필드들이 나오도록 하기 위해 isBroker를 추가함
const isResidentSalesTypes = (form: Pick<FormArticleType, 'salesType' | 'writerType'>) =>
  ResidentSalesTypes.some((type) => type === form.salesType) || isBroker(form);

export const ArticleConfig = {
  [ArticleFieldKey.WriterType]: {
    title: '글 작성자',
    validate: writerTypeSchema.safeParse,
    isRequired: () => true,
  },
  [ArticleFieldKey.BuildingUsage]: {
    title: '건축물 용도',
    validate: buildingUsageSchema.safeParse,
    isRequired: () => true,
    include: (form) => form.salesType !== 'ETC',
  },
  [ArticleFieldKey.SalesType]: {
    title: '매물 종류',
    validate: salesTypeSchema.safeParse,
    isRequired: () => true,
  },
  [ArticleFieldKey.Address]: {
    title: '주소',
    validate: addressSchema.safeParse,
    isRequired: () => true,
  },
  [ArticleFieldKey.AddressInfo]: {
    title: '위치 한줄 설명',
  },
  [ArticleFieldKey.DongHo]: {
    title: '동,호수',
    validate: dongHoSchema.safeParse,
  },
  [ArticleFieldKey.BuildingName]: {
    title: '건물명',
    validate: buildingNameSchema.safeParse,
    include: (form) => isOneOfSalesType(form.salesType, BuildingNameAvailableSalesTypes),
  },
  [ArticleFieldKey.Trade]: {
    title: '거래 방식',
    validate: tradesSchema.safeParse,
    isRequired: () => true,
  },
  [ArticleFieldKey.PremiumMoney]: {
    title: '권리금',
    validate: premiumMoneySchema.safeParse,
    include: (form) => form.salesType === 'STORE',
  },
  [ArticleFieldKey.Image]: {
    title: '사진',
    validate: imagesSchema.safeParse,
  },
  [ArticleFieldKey.Video]: {
    title: '동영상',
  },
  [ArticleFieldKey.Orientation]: {
    title: '방향',
    validate: orientationSchema.safeParse,
    isRequired: isBroker,
  },
  [ArticleFieldKey.AreaInfo]: {
    title: '매물 정보(평/층)',
    validate: areaInfoSchema.safeParse,
    isRequired: isBroker,
  },
  [ArticleFieldKey.Area]: {
    title: '전용면적',
    validate: areaSchema.safeParse,
    isRequired: () => true,
  },
  [ArticleFieldKey.SupplyArea]: {
    title: '공급면적',
    validate: supplyAreaSchema.safeParse,
    include: (form) => !isOneOfSalesType(form.salesType, OneRoomSalesTypes),
  },
  [ArticleFieldKey.Floor]: {
    title: '층',
    validate: floorSchema.safeParse,
    isRequired: isBroker,
  },
  [ArticleFieldKey.Room]: {
    title: '방/욕실 수',
    validate: roomSchema.safeParse,
    isRequired: isBroker,
    include: isResidentSalesTypes,
  },
  [ArticleFieldKey.BuildingApprovalDate]: {
    title: '사용승인일 (연식)',
    validate: buildingApprovalDateSchema.safeParse,
    isRequired: isBroker,
    include: isBroker,
  },
  [ArticleFieldKey.ManageCost]: {
    title: '관리비',
    validate: manageCostSchema.safeParse,
    include: isResidentSalesTypes,
    isRequired: isBroker,
  },
  [ArticleFieldKey.RequiredOption]: {
    title: '입주 가능 조건',
    validate: requiredOptionSchema.safeParse,
    include: isResidentSalesTypes,
    isRequired: isBroker,
  },
  [ArticleFieldKey.MoveInDate]: {
    title: '입주가능일',
    include: isResidentSalesTypes,
    validate: moveInDateSchema.safeParse,
    isRequired: isBroker,
  },
  [ArticleFieldKey.Option]: {
    title: '내외부 시설',
    include: isResidentSalesTypes,
  },
  [ArticleFieldKey.Qualitative]: {
    title: '매물 장점',
    include: isResidentSalesTypes,
    validate: qualitativeSchema.safeParse,
  },
  [ArticleFieldKey.Description]: {
    title: '매물 소개',
  },
} as const satisfies Record<ArticleFieldKey, ArticleField>;

// https://apis.map.kakao.com/web/documentation/#RoadviewClient
export const MAX_ROADVIEW_METERS = 20;

export const filterType = {
  salesType: '매물 종류',
  tradeType: '거래 유형',
  price: '가격',
  area: '평수',
  floor: '층수',
  buildingApprovalDate: '사용승인일',
  withVideo: '매물 소개 영상',
  options: '매물 조건',
  etc: '기타',
};
export type FilterType = keyof typeof filterType;

export const AreaChipOptions = {
  OPEN_ONE_ROOM: ['19.8', '23.1', '26.4', '29.7', '33.0'],
  SPLIT_ONE_ROOM: ['19.8', '23.1', '26.4', '29.7', '33.0'],
  TWO_ROOM: ['33.0', '39.6', '49.5', '59.4', '66.0'],
  APART: ['59', '74', '84', '108', '114'],
  OFFICETEL: ['19.8', '23.1', '26.4', '29.7', '33.0'],
  STORE: ['33.0', '39.6', '49.5', '66.0', '99.2'],
  ETC: ['33.0', '39.6', '49.5', '66.0', '99.2'],
} satisfies Record<SalesTypeEnum, string[]>;
