import React from 'react';
import styled from '@emotion/styled';
import Placeholder from 'components/Base/Skeleton/Placeholder';
import { css } from '@emotion/react';
import { Stack } from 'components/Base/Stack/Stack';

export default function ResultSkeleton() {
  return (
    <Wrapper>
      <div className="px-5">
        <div className="mt-3 flex flex-col items-center gap-4">
          <img
            src="https://asset-town.krrt.io/production/illust/a573bcfd-d798-4d3e-bdbb-c1b4921649d2/7caaaceca8a2efb450e5a688f38a6d174d2e1e40.webp"
            width={64}
            height={64}
          />

          <div className="flex flex-col items-center gap-[5px]">
            <Placeholder.Text
              css={css`
                opacity: 0.2;
              `}
            >
              이웃들의
            </Placeholder.Text>

            <Placeholder.Text
              css={css`
                opacity: 0.2;
              `}
            >
              2024 당근부동산 여정을 <br />
            </Placeholder.Text>

            <Placeholder.Text
              css={css`
                opacity: 0.2;
              `}
            >
              함께 살펴봤어요!
            </Placeholder.Text>
          </div>
        </div>

        <Stack spacing={12} className="mt-6">
          {/* 매물 조회수 */}
          <Placeholder.Box
            css={css`
              width: 100%;
              height: 60px;
              flex-shrink: 0;
              border-radius: 12px;
              opacity: 0.2;
            `}
          />

          {/* 매물 유형 태그 */}
          <Placeholder.Box
            css={css`
              width: 100%;
              height: 100px;
              flex-shrink: 0;
              border-radius: 12px;
              opacity: 0.2;
            `}
          />
        </Stack>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;
`;
